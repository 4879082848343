import React from "react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";

const getDBResourceTotal = gql`
  query getDBResourceTotal($searchForm: SearchForm) {
    result: getDBResourceTotal(Input: $searchForm) {
      total
    }
  }
`;

const getJournalResourceTotal = gql`
  query getJournalResourceTotal($searchForm: SearchForm) {
    result: getJournalResourceTotal(Input: $searchForm) {
      total
    }
  }
`;

@inject("appStore")
@observer
class ResourceListTab extends React.Component {
  constructor(props) {
    super(props);

    let isDatabases =
      props.appStore.globalConfig["jumperrwd.common.isDatabases"];
    let isJournals = props.appStore.globalConfig["jumperrwd.common.isJournals"];
    let ispjournals = props.appStore.globalConfig["jumper.common.ispjournals"];
    let isEbooks = props.appStore.globalConfig["jumperrwd.common.isEbooks"];
    this.state = {
      showTabs: false,
      showDatabase: isDatabases === "1",
      showJournal: isJournals === "1",
      showPJournal: ispjournals === "1",
      showEbook: isEbooks === "1",
    };
  }

  async componentDidMount() {
    if (
      this.props.searchForm.pageType === "search" &&
      !this.props.searchForm.searchTarget &&
      this.props.searchForm.filterField.length === 0
    ) {
      let {
        dbSearchForm,
        journalSearchForm,
        pJournalSearchForm,
        ebookSearchForm,
      } = await this.getSearchForms(this.props);

      let dbResourceCount = await this.getDBResourceCount(dbSearchForm);
      let journalResourceCount = await this.getJournalResourceCount(
        journalSearchForm
      );
      let pJournalResourceCount = await this.getJournalResourceCount(
        pJournalSearchForm
      );
      let ebookResourceCount = await this.getJournalResourceCount(
        ebookSearchForm
      );

      // 變更資源數量
      if (this.state.showDatabase) {
        this.props.appStore.setDBResourceCount(dbResourceCount);
      }
      if (this.state.showJournal) {
        this.props.appStore.setJournalResourceCount(journalResourceCount);
      }
      if (this.state.showPJournal) {
        this.props.appStore.setPJournalResourceCount(pJournalResourceCount);
      }
      if (this.state.showEbook) {
        this.props.appStore.setEbookResourceCount(ebookResourceCount);
      }

      this.changeTab(
        this.props,
        dbResourceCount,
        journalResourceCount,
        pJournalResourceCount,
        ebookResourceCount
      );
    }
  }

  async UNSAFE_componentWillReceiveProps(props) {
    if (
      props.searchForm.pageType === "search" &&
      !props.searchForm.searchTarget &&
      props.searchForm.filterField.length === 0 &&
      (props.resourceType !== this.props.resourceType ||
        JSON.stringify(props.searchForm.searchInput) !==
          JSON.stringify(this.props.searchForm.searchInput) ||
        props.searchForm.searchTarget !== this.props.searchForm.searchTarget)
    ) {
      let {
        dbResourceCount,
        journalResourceCount,
        pJournalResourceCount,
        ebookResourceCount,
      } = props.appStore;
      let {
        dbSearchForm,
        journalSearchForm,
        pJournalSearchForm,
        ebookSearchForm,
      } = await this.getSearchForms(props);
      // 確認資源數量
      dbResourceCount = await this.getDBResourceCount(dbSearchForm);
      journalResourceCount = await this.getJournalResourceCount(
        journalSearchForm
      );
      pJournalResourceCount = await this.getJournalResourceCount(
        pJournalSearchForm
      );
      ebookResourceCount = await this.getJournalResourceCount(ebookSearchForm);

      // 變更資源數量
      if (
        this.state.showDatabase &&
        dbResourceCount !== props.appStore.dbResourceCount
      ) {
        props.appStore.setDBResourceCount(dbResourceCount);
      }
      if (
        this.state.showJournal &&
        journalResourceCount !== props.appStore.journalResourceCount
      ) {
        props.appStore.setJournalResourceCount(journalResourceCount);
      }
      if (
        this.state.showPJournal &&
        pJournalResourceCount !== props.appStore.pJournalResourceCount
      ) {
        props.appStore.setPJournalResourceCount(pJournalResourceCount);
      }
      if (
        this.state.showEbook &&
        ebookResourceCount !== props.appStore.ebookResourceCount
      ) {
        props.appStore.setEbookResourceCount(ebookResourceCount);
      }
      this.changeTab(
        props,
        dbResourceCount,
        journalResourceCount,
        pJournalResourceCount,
        ebookResourceCount
      );
    }
  }

  @autobind
  changeTab(
    props,
    dbResourceCount,
    journalResourceCount,
    pJournalResourceCount,
    ebookResourceCount
  ) {
    let { resourceConfig } = props.appStore;
    if (
      this.state.showDatabase &&
      props.resourceType === "databases" &&
      dbResourceCount === 0
    ) {
      if (resourceConfig && resourceConfig.resourceTabList) {
        let breakPoint = false;
        resourceConfig.resourceTabList.map((resourceTab) => {
          if (breakPoint) {
            return "";
          }
          if (resourceTab === "journal" && journalResourceCount > 0) {
            this.setResourceType("journal");
            breakPoint = true;
          } else if (
            resourceTab === "paper_journal" &&
            pJournalResourceCount > 0
          ) {
            this.setResourceType("paper_journal");
            breakPoint = true;
          } else if (resourceTab === "ebook" && ebookResourceCount > 0) {
            this.setResourceType("ebook");
            breakPoint = true;
          }
          return "";
        });
      }
    } else if (
      this.state.showJournal &&
      props.resourceType === "journal" &&
      journalResourceCount === 0
    ) {
      if (resourceConfig && resourceConfig.resourceTabList) {
        let breakPoint = false;
        resourceConfig.resourceTabList.map((resourceTab) => {
          if (breakPoint) {
            return "";
          }
          if (resourceTab === "databases" && dbResourceCount > 0) {
            this.setResourceType("databases");
            breakPoint = true;
          } else if (
            resourceTab === "paper_journal" &&
            pJournalResourceCount > 0
          ) {
            this.setResourceType("paper_journal");
            breakPoint = true;
          } else if (resourceTab === "ebook" && ebookResourceCount > 0) {
            this.setResourceType("ebook");
            breakPoint = true;
          }
          return "";
        });
      }
    } else if (
      this.state.showPJournal &&
      props.resourceType === "paper_journal" &&
      pJournalResourceCount === 0
    ) {
      if (resourceConfig && resourceConfig.resourceTabList) {
        let breakPoint = false;
        resourceConfig.resourceTabList.map((resourceTab) => {
          if (breakPoint) {
            return "";
          }
          if (resourceTab === "databases" && dbResourceCount > 0) {
            this.setResourceType("databases");
            breakPoint = true;
          } else if (resourceTab === "jouranl" && journalResourceCount > 0) {
            this.setResourceType("jouranl");
            breakPoint = true;
          } else if (resourceTab === "ebook" && ebookResourceCount > 0) {
            this.setResourceType("ebook");
            breakPoint = true;
          }
          return "";
        });
      }
    } else if (
      this.state.showEbook &&
      props.resourceType === "ebook" &&
      ebookResourceCount === 0
    ) {
      let breakPoint = false;
      if (resourceConfig && resourceConfig.resourceTabList) {
        resourceConfig.resourceTabList.map((resourceTab) => {
          if (breakPoint) {
            return "";
          }
          if (resourceTab === "databases" && dbResourceCount > 0) {
            this.setResourceType("databases");
            breakPoint = true;
          } else if (resourceTab === "jouranl" && journalResourceCount > 0) {
            this.setResourceType("jouranl");
            breakPoint = true;
          } else if (
            resourceTab === "paper_journal" &&
            pJournalResourceCount > 0
          ) {
            this.setResourceType("paper_journal");
            breakPoint = true;
          }
          return "";
        });
      }
    }
  }

  @autobind
  async getSearchForms(props) {
    let dbSearchForm = {
      ...props.searchForm,
      sort: "",
      filterField: [],
      filterValue: [],
      resourceType: "databases",
    };
    delete dbSearchForm["publicationType"];
    let journalSearchForm = {
      ...props.searchForm,
      sort: "",
      filterField: [],
      filterValue: [],
      resourceType: "journal",
      publicationType: "1",
    };
    let pJournalSearchForm = {
      ...props.searchForm,
      sort: "",
      filterField: [],
      filterValue: [],
      publicationType: "3",
      resourceType: "paper_journal",
    };
    let ebookSearchForm = {
      ...props.searchForm,
      sort: "",
      filterField: [],
      filterValue: [],
      publicationType: "2",
      resourceType: "ebook",
    };
    return {
      dbSearchForm,
      journalSearchForm,
      pJournalSearchForm,
      ebookSearchForm,
    };
  }

  @autobind
  async getDBResourceCount(form) {
    let dbResourceCount = 0;
    dbResourceCount = client.jumperrwdClient
      .query({
        query: getDBResourceTotal,
        variables: {
          searchForm: form,
        },
      })
      .then((res) => {
        let total = 0;
        if (res.data && res.data.result) {
          total = res.data.result.total;
        }
        return total;
      });
    return dbResourceCount;
  }

  @autobind
  async getJournalResourceCount(form) {
    let journalResourceCount = 0;
    journalResourceCount = client.jumperrwdClient
      .query({
        query: getJournalResourceTotal,
        variables: {
          searchForm: form,
        },
      })
      .then((res) => {
        let total = 0;
        if (res.data && res.data.result) {
          total = res.data.result.total;
        }
        return total;
      });
    return journalResourceCount;
  }

  @autobind
  changeResourceType(resourceType, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = {};
    let pageType = "search";
    if (search.pageType !== "search") {
      pageType = "list";
    }
    params["pageType"] = pageType;
    params["resourceType"] = resourceType;
    if (search.searchInput) {
      params["searchInput"] = search.searchInput;
    }
    navigate([location.pathname, qs.stringify(params)].join("?"));
    if (this.state.showTabs) {
      this.setState({
        showTabs: false,
      });
    }
  }

  @autobind
  handleShowTabs(e) {
    e.preventDefault();
    this.setState({
      showTabs: !this.state.showTabs,
    });
  }

  @autobind
  setResourceType(resourceType) {
    let { search, location } = this.props;
    let params = { ...search };
    params.resourceType = resourceType;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  render() {
    let { resourceType, appStore, searchForm, t, search } = this.props;
    let {
      dbResourceCount,
      journalResourceCount,
      pJournalResourceCount,
      ebookResourceCount,
      resourceConfig,
      searchBlock,
    } = appStore;
    let { pageType, searchTarget } = searchForm;
    let { domain = "" } = search;
    domain = domain !== "" ? domain : "default";
    let { showTabs, showDatabase, showJournal, showPJournal, showEbook } =
      this.state;
    let databasesShow = showDatabase,
      journalShow = showJournal,
      pJournalShow = showPJournal,
      ebookShow = showEbook;
    if (searchTarget) {
      if (searchTarget !== "databases") {
        databasesShow = false;
      }
      if (searchTarget !== "journal") {
        journalShow = false;
      }
      if (searchTarget !== "paper_journal") {
        pJournalShow = false;
      }
      if (searchTarget !== "ebook") {
        ebookShow = false;
      }
    }
    let resoureceTabName = "";
    if (searchBlock && searchBlock.searchTypeList) {
      let searchTypeItem = null;
      searchBlock.searchTypeList.map((searchTypeItemTemp) => {
        if (
          searchTypeItemTemp.type === this.props.searchType &&
          searchTypeItemTemp.domain === domain
        ) {
          searchTypeItem = searchTypeItemTemp;
        }
        return "";
      });
      if (searchTypeItem && searchTarget) {
        if (searchTypeItem.searchTarget === searchTarget) {
          resoureceTabName = searchTypeItem.message
            ? this.props.t(searchTypeItem.message)
            : searchTypeItem.name;
        }
      }
    }
    return (
      <>
        <div className="main_tabSet">
          <div className="container">
            <button
              type="button"
              tabIndex="0"
              className="active_m"
              onClick={this.handleShowTabs}>
              {resourceType === "databases" && (
                <>
                  {resoureceTabName
                    ? resoureceTabName
                    : t("jumper.common.leftmenu.databases")}
                  {pageType === "search" && (
                    <>
                      <span>({dbResourceCount})</span>
                    </>
                  )}
                </>
              )}
              {resourceType === "journal" && (
                <>
                  {resoureceTabName
                    ? resoureceTabName
                    : t("jumper.common.leftmenu.ejournals")}
                  {pageType === "search" && (
                    <>
                      <span>{journalResourceCount})</span>
                    </>
                  )}
                </>
              )}
              {resourceType === "paper_journal" && (
                <>
                  {resoureceTabName
                    ? resoureceTabName
                    : t("jumper.common.leftmenu.pjournals")}
                  {pageType === "search" && (
                    <>
                      <span>({pJournalResourceCount})</span>
                    </>
                  )}
                </>
              )}
              {resourceType === "ebook" && (
                <>
                  {resoureceTabName
                    ? resoureceTabName
                    : t("jumper.common.leftmenu.ebooks")}
                  {pageType === "search" && (
                    <>
                      <span>({ebookResourceCount})</span>
                    </>
                  )}
                </>
              )}
            </button>
            <ul className="tabs" style={{ display: showTabs ? "block" : "" }}>
              {resourceConfig &&
                resourceConfig.resourceTabList &&
                resourceConfig.resourceTabList.map((resourceTab) => {
                  if (resourceTab === "databases" && databasesShow) {
                    return (
                      <li
                        className={
                          resourceType === "databases" ? "active" : ""
                        }>
                        <a
                          tabIndex="0"
                          role="button"
                          onClick={this.changeResourceType.bind(
                            this,
                            "databases"
                          )}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.changeResourceType("databases", e);
                            }
                          }}>
                          {resoureceTabName
                            ? resoureceTabName
                            : t("jumper.common.leftmenu.databases")}
                          {pageType === "search" && (
                            <>
                              <span>({dbResourceCount})</span>
                            </>
                          )}
                        </a>
                      </li>
                    );
                  } else if (resourceTab === "journal" && journalShow) {
                    return (
                      <li
                        className={resourceType === "journal" ? "active" : ""}>
                        <a
                          tabIndex="0"
                          onClick={this.changeResourceType.bind(
                            this,
                            "journal"
                          )}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.changeResourceType("journal", e);
                            }
                          }}>
                          {resoureceTabName
                            ? resoureceTabName
                            : t("jumper.common.leftmenu.ejournals")}
                          {pageType === "search" && (
                            <>
                              <span>({journalResourceCount})</span>
                            </>
                          )}
                        </a>
                      </li>
                    );
                  } else if (resourceTab === "paper_journal" && pJournalShow) {
                    return (
                      <li
                        className={
                          resourceType === "paper_journal" ? "active" : ""
                        }>
                        <a
                          tabIndex="0"
                          onClick={this.changeResourceType.bind(
                            this,
                            "paper_journal"
                          )}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.changeResourceType("paper_journal", e);
                            }
                          }}>
                          {resoureceTabName
                            ? resoureceTabName
                            : t("jumper.common.leftmenu.pjournals")}
                          {pageType === "search" && (
                            <>
                              <span>({pJournalResourceCount})</span>
                            </>
                          )}
                        </a>
                      </li>
                    );
                  } else if (resourceTab === "ebook" && ebookShow) {
                    return (
                      <li className={resourceType === "ebook" ? "active" : ""}>
                        <a
                          tabIndex="0"
                          onClick={this.changeResourceType.bind(this, "ebook")}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.changeResourceType("ebook", e);
                            }
                          }}>
                          {resoureceTabName
                            ? resoureceTabName
                            : t("jumper.common.leftmenu.ebooks")}
                          {pageType === "search" && (
                            <>
                              <span>({ebookResourceCount})</span>
                            </>
                          )}
                        </a>
                      </li>
                    );
                  }
                  return "";
                })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default ResourceListTab;
